import { Entry } from 'contentful'
import { IServiceDetail } from './ServiceDetailsSection'
import { mapContentfulImageAsset } from '@utils/contentful/contentfulImage'
import { GenericContentBlock } from 'types/contentful/genericContentBlock'

export function isProductCustomisable(productSlug: string) {
  return productSlug?.includes('custom')
}

export function mapContentBlockToServiceDetails(
  genericContentBlock: Entry<GenericContentBlock>
): IServiceDetail {
  if (!genericContentBlock) {
    return null
  }

  return {
    label: genericContentBlock?.fields?.h1,
    labelIcon:
      (genericContentBlock?.fields?.primaryImages?.[0] &&
        mapContentfulImageAsset(
          genericContentBlock?.fields?.primaryImages?.[0]
        )) ||
      null,
    content: genericContentBlock?.fields?.h2,
  }
}
